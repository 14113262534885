import React, { useState, useEffect } from 'react';
import { Button } from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { usePreferences } from 'hooks/usePreferences';
import { useLocation } from 'hooks/useLocation';
import { useStickyButton } from 'hooks/useStickyButton';
import { AnalyticsEvents, event } from 'utils/analytics';

import CloseButton from './CloseButton';

export type userLocation =
  | 'switch location'
  | 'stay location'
  | 'choose location';

export const locationGA4CustomEvent = (
  userAction: userLocation,
  newLocation: string
) => {
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.SELECT_LOCATION,
    event_params: {
      user_action: userAction,
      new_location: newLocation,
    },
  });
};

const LocationCheck: React.FC = () => {
  const t = useTranslate();

  const { location, setLocation } = useLocation();
  const { siteCountry, userCountry } = location || {};

  const { preferences, setPreferences } = usePreferences();
  const [shouldShow, setShouldShow] = useState(false);

  const { setShowStickyButton } = useStickyButton();

  useEffect(() => {
    if (siteCountry?.id && userCountry?.id && shouldShow) {
      setShowStickyButton(false);
    }
  }, [setShowStickyButton, shouldShow, siteCountry?.id, userCountry?.id]);

  useEffect(() => {
    const differentCountry = siteCountry?.id !== userCountry?.id;
    setShouldShow(!!(differentCountry && !preferences['prefers-region']));
    if (userCountry && !differentCountry) {
      setPreferences(curr => ({
        ...curr,
        'prefers-region': true,
      }));
    }
  }, [siteCountry, userCountry]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleStayInRegion = () => {
    setPreferences(curr => ({
      ...curr,
      'prefers-region': true,
    }));
    locationGA4CustomEvent('stay location', siteCountry.name);
    setShouldShow(false);
  };

  return siteCountry?.id && userCountry?.id && shouldShow ? (
    <section
      className="fixed z-20 left-1/2 transform -translate-x-1/2 right-0 bottom-0 tablet:transform-none tablet:left-auto desktop:transform-none desktop:left-auto bg-white shadow-xl p-6 xs:p-8 space-y-6 font-display flex flex-col border mr-5 mb-5 rounded w-80 xs:w-96 max-w-full"
      data-test-id="country-selector"
    >
      <div className="flex flex-col space-y-2">
        <div className="flex items-center justify-between">
          <h1 className="font-bold text-xl">{t('selectLocation')}</h1>
          <CloseButton onClick={handleStayInRegion} />
        </div>
        <p className="text-sm" data-test-id="switch-region-text">
          {/* TODO: Handle translation of siteRegion */}
          {t<'switchRegion'>('switchRegion', {
            siteRegion: siteCountry.name,
            userCountry: userCountry.name,
          })}
        </p>
      </div>
      <div className="flex flex-col space-y-2 w-full">
        <a href={userCountry.url} className="w-full">
          {/* TODO: Handle translation of userCountry? */}
          <Button
            label={`${t('switchTo')} ${userCountry.name}`}
            variant="primary"
            dataTestId="switch-to-button"
            className="w-full"
            onClick={() => {
              locationGA4CustomEvent('switch location', userCountry.name);
            }}
          />
        </a>

        {/* TODO: Handle translation of siteRegion? */}
        <Button
          label={`${t('stayOn')} ${siteCountry.name}`}
          dataTestId="stay-on-region-button"
          variant="secondary"
          onClick={handleStayInRegion}
        />
        <Button
          onClick={() => setLocation({ ...location, selecting: true })}
          label={t('chooseLocation')}
          dataTestId="choose-location"
          variant="secondary"
        />
      </div>
    </section>
  ) : null;
};

export default LocationCheck;
